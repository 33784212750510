<template lang="pug">
    div(:class="contents.content.alignment || 'left'")
        a(:href="file", target="_blank").botao {{ contents.content.name }}
</template>

<script>
import { props, components } from '@/mixins/component'

export default {
    name: "component-botao-com-arquivo",
    props,
    components,
    computed: {
        file() {
            return this.contents.content.file.filePath
        }
    }
}
</script>

<style lang="stylus" scoped src="./BotaoArquivo.styl"></style>
